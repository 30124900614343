body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
App class div should occupy at most 80% of the width of the screen
height should be 90% of the screen
 */
.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
styling for button
no shadow, white background, black text, no border, font-size 20px, padding 10px 20px, margin 10px
*/
button {
  box-shadow: none;
  background-color: white;
  color: black;
  cursor: pointer;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  margin: 5px;
}

/*
styling for button with class active
thick black border no shadow and black text
should not alter the overall size of the button
*/
button.active {
  outline: 5px solid black;
  box-shadow: none;
  color: black;
}

/*
styling for span text with class problem and correct
text should be bold and green
 */
span.problem.correct {
  font-weight: bold;
  color: green;
}

/*
styling for span text with class problem and wrong
text should be bold and red
 */
span.problem.wrong {
  font-weight: bold;
  color: red;
}

/*
styling for button with class active and correct
border should be green
 */
button.active.correct {
  outline: 5px solid green;
}

/*
styling for button with class active and wrong
border should be red
 */
button.active.wrong {
  outline: 5px solid red;
}

/*
list item styling
no bullet points, margin 10px 0, padding 0
*/
li {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}

/*
table of profits with className profitTable should always be centered horizontally
 */
.profitTable {
  margin: 0 auto;
}